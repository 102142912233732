export const parseCartSaveForLater = value =>
  value.split(',').reduce((acc, cur) => {
    const [productId, quantity] = cur.split(':');
    const parsedQuantity = parseInt(quantity, 10);

    // for gift cards productId also contains selected sku ("<id>|<selectedSku>")
    // example:
    // const [id, sku] = productId.split('|');

    if (!isNaN(parsedQuantity)) {
      const [id, sku] = productId.includes('|') ? productId.split('|') : [productId, null];
      acc[id === sku ? id : productId] = parsedQuantity;
    }

    return acc;
  }, {});
