import {
  getSessionContext,
  getSearchRepository,
  getPage,
  getContentItemsList
} from '@oracle-cx-commerce/commerce-utils/selector';
import {parseCartSaveForLater} from './selectors-helpers';

export const getplcbB2cRepository = state => state.plcbB2cRepository || {};

export const getSelectedStore = state => getplcbB2cRepository(state).selectedStore;

export const getFulfillmentMethod = state => getplcbB2cRepository(state).fulfillmentMethod || null;

export const getSelectedStoreAddress = state => getplcbB2cRepository(state).selectedStoreAddress || {};

export const getCartSaveForLater = state => getplcbB2cRepository(state).cartSaveForLater || '';

export const getCartSaveForLaterParsed = state => parseCartSaveForLater(getCartSaveForLater(state));

export const getAlertsViewed = state => getplcbB2cRepository(state).alertsViewed || '';

export const getStoreModal = state => getplcbB2cRepository(state).storeModal || {displayStoreModal: false};
export const getBopisEnabledModal = state => getplcbB2cRepository(state).bopisEnabledModal || null;

export const getDisplayStoreModal = state => getStoreModal(state).displayStoreModal || false;
export const getEventsPageInfo = state => getStoreModal(state).eventsPage || false;
export const getCheckoutPageInfo = state => getStoreModal(state).checkoutPage || false;

export const getStoreModalChangeFullfillment = state => getStoreModal(state).changeFullfillment;
export const getStoreModalUpdateShippingGroups = state => getStoreModal(state).updateShippingGroups;

export const getFulfillmentPopoverVisibility = state => getplcbB2cRepository(state).fulfillmentPopoverVisible || false;

export const getFulfillmentModalVisibility = state => getplcbB2cRepository(state).fulfillmentModalVisible || false;

export const getLoggedOutModal = state =>
  getplcbB2cRepository(state).loggedOutModal || {
    loggedOutModalVisible: false
  };

export const getLoggedOutModalVisibility = state =>
  typeof getLoggedOutModal(state).loggedOutModalVisible === 'boolean'
    ? getLoggedOutModal(state).loggedOutModalVisible
    : null;

export const getOrderUpdateFailedModalVisibility = state =>
  typeof getplcbB2cRepository(state).orderUpdateFailedModalVisible === 'boolean'
    ? getplcbB2cRepository(state).orderUpdateFailedModalVisible
    : null;

export const getChangeFulfillmentMethodModalVisibility = state =>
  getplcbB2cRepository(state).changeFulfillmentMethodModalVisibility || false;

export const getStoreModalAddToCart = state => getStoreModal(state).storeModalAddToCart || false;

export const getPreviousFulfillmentMethod = state => getplcbB2cRepository(state).previousFulfillmentMethod || null;

export const getPreviousSelectedStore = state => getplcbB2cRepository(state).previousSelectedStore || null;

export const getChangeFulfillmentMethodModalType = state =>
  getplcbB2cRepository(state).changeFulfillmentMethodModalType || null;

export const getPreviousSelectedStoreAddress = state => getplcbB2cRepository(state).previousSelectedStoreAddress || {};

export const getCheckoutMetadata = state => getplcbB2cRepository(state).checkoutMetadata || null;

export const isCouponsOnPageDisplayedSelector = state => !!getplcbB2cRepository(state).displayCoupons;

export const getEventStoreId = state => getplcbB2cRepository(state).eventStoreId || null;

export const getRecipePosition = state => getplcbB2cRepository(state).recipePosition || null;

export const getRecipeIdData = state => getplcbB2cRepository(state).recipeId || null;

export const getRecipeTaxonomyData = state => getplcbB2cRepository(state).recipeTaxonomy || {};

export const getLicenseeValidationStatus = state => getplcbB2cRepository(state).licenseeValidationComplete || false;

const getCatalogRepository = state => state.catalogRepository || {};

export const getNavCategories = state => getCatalogRepository(state).navCategories || {};

export const getPendingPromoCodes = state => getplcbB2cRepository(state).pendingPromoCodes || '';

export const getSelectedStoreEventsPage = state => getplcbB2cRepository(state).selectedStoreEventsPage || null;

export const getSelectedStoreLotteryPage = state => getplcbB2cRepository(state).selectedStoreLotteryPage || null;

export const getGiftCardsQuantityCartState = state => getplcbB2cRepository(state).giftCardQuantityTruncated || false;

export const getSaveToLocalStorage = state => {
  const session = getSessionContext(state);

  return {
    clientRepository: {
      context: {session}
    },
    plcbB2cRepository: {
      selectedStore: getSelectedStore(state),
      bopisEnabledModal: getBopisEnabledModal(state),
      loggedOutModal: getLoggedOutModal(state),
      orderUpdateFailedModalVisible: getOrderUpdateFailedModalVisibility(state),
      fulfillmentMethod: getFulfillmentMethod(state),
      selectedStoreAddress: getSelectedStoreAddress(state),
      cartSaveForLater: getCartSaveForLater(state),
      alertsViewed: getAlertsViewed(state),
      checkoutMetadata: getCheckoutMetadata(state),
      pendingPromoCodes: getPendingPromoCodes(state),
      selectedStoreEventsPage: getSelectedStoreEventsPage(state),
      giftCardQuantityTruncated: getGiftCardsQuantityCartState(state)
    }
  };
};

export const getProductTypeaheadSearchResults = state => getSearchRepository(state).productTypeaheadSearchResults || {};
// Pass pageId from props
export const _getContentWidgetItemIds = (state, props) => {
  const page = getPage(state, props);
  const {widgetId, id} = props;

  let widgetName;
  if (widgetId !== undefined) {
    widgetName = `contentWidget-${widgetId}`;
  } else {
    widgetName = `contentWidget-${id}`;
  }

  return page[widgetName] || {};
};

export const _getContentItems = (state, props) => {
  const contentItemIds = _getContentWidgetItemIds(state, props);

  if (contentItemIds && Object.keys(contentItemIds).length !== 0) {
    const contentItemList = getContentItemsList(state);
    const {pageType} = getPage(state, props);
    if (pageType === 'profile') {
      return {contentItems: contentItemList};
    }
    const contentItemKeys = Object.keys(contentItemIds);
    const items = {};
    contentItemKeys.forEach(key => {
      items[key] = contentItemList[key];
    });
    // don't return new object in selector

    return {contentItems: items};
  }

  return {};
};

export const getCouponTitleMapping = state => getplcbB2cRepository(state).couponTitleMapping || {};

export const getCurrentProfileOrderHistory = state => getplcbB2cRepository(state).currentProfileOrders || [];

export const getLimitPerOrderTruncatedItems = state => getplcbB2cRepository(state).limitPerOrderTruncated || {};
